import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const HeroWrapper = styled.div`
  position: relative;
  text-align: center;
`

const HeroText = styled.div`
  position: relative;
  padding: 40px;
  text-align: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  z-index: 100;
  h1 {
    margin-bottom: 40px;
  }
  @media (min-width: 760px) {
    color: #fff;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "window-cleaning-2.jpg" }) {
          childImageSharp {
            fluid(
              maxWidth: 2000
              maxHeight: 1000
              cropFocus: CENTER
              quality: 100
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <HeroWrapper>
        <Img
          fluid={data.file.childImageSharp.fluid}
          alt="A clean supreme window cleaning van in Brentwood countryside"
          loading="eager"
        />
        <HeroText>{children}</HeroText>
      </HeroWrapper>
    )}
  />
)
