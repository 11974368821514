import React from 'react';
import Faq from './faq'

const Faqs = () => (
  <>
    <Faq
      question="How often do you clean windows?"
      answer="We clean windows on a 4 weekly (approx.1 monthly) or 8 weekly basis. Exceptions can be made for commercial and larger jobs."
    />
    <Faq
      question="Can you clean windows on a one-off basis?"
      answer="Yes, we can offer a 'one-off' clean, although the price will be slightly more than a regular arrangement service."
    />
    <Faq
      question="Can you clean the internal windows?"
      answer="Yes, we are able to clean the internal windows of any property using the traditional method of window cleaning. Please contact us regarding a quote for internal windows."
    />
    <Faq
      question="Which methods of payment do you accept?"
      answer="We currently accept payment methods such as: Debit/Credit Card, Apple/Google Pay, Cash, Cheque, Online Transfer and BACS."
    />
    <Faq
      question="How will you notify me of upcoming cleans?"
      answer="We send out a text message or phone 2 days before your windows are due to be cleaned incase any arrangements need to be made such as unbolting a gate etc."
    />
    <Faq
      question="What happens if I am not home when you call to clean the windows?"
      answer="After cleaning the windows we will put a card through your letterbox with the date and the amount owed on it. Then you have the option to pay online, send a cheque or we will arrange to call back and collect the same day at a convenient time to you."
    />
    <Faq
      question="Will the water leave spots/marks when it dries?"
      answer="No, your windows will be spot free when the pure water has dried."
    />
  </>
)

export default Faqs;
