import React, { Component } from 'react'
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Container
} from 'reactstrap';
import './testimonials.scss'

const items = [
  {
    id: 1,
    captionHeader: 'The standard of work is excellent and the speed and quality of service is exactly what we needed.',
    captionText: '- Peter Jamieson, Colchester'
  },
  {
    id: 2,
    captionHeader: 'Supreme Cleaning were recommended and i have been thoroughly impressed with the prompt, quality service.',
    captionText: '- Adam Branch, Brentwood'
  },
  {
    id: 3,
    captionHeader: 'We have been customers of Supreme Cleaning for many years now and have always been pleased with their work',
    captionText: '- Mrs V. Finn, Chelmsford'
  },
  {
    id: 4,
    captionHeader: 'Very courteous and professional, well presented and reliable. Great attention to detail when we asked for our cills etc to be included. A fair price for a professional job. Would have no hesitation in recommending.',
    captionText: '- Matthew, Chelmsford'
  }
];


class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;

    const slides = items.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.id}
          className="testimonial"
        >
          <Container>
            <p className="lead">{item.captionHeader}</p>
            <p>{item.captionText}</p>
          </Container>
        </CarouselItem>
      );
    });

    return (
      <>
      <Carousel
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}
        interval={10000}
      >
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
      </Carousel>
      </>
    );
  }
}


export default Testimonials;
