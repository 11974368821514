import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Row, Col, Card, CardHeader } from 'reactstrap'
import styled from 'styled-components'

const StyledCard = styled(Card)`
  border-radius: 0.5rem;
  box-shadow: #d4d4d4 0 4px 12px 4px;
  height: 100%;
  .card-header {
    border-bottom: 0;
    background: none;
    padding: 1.5rem 1.25rem;
    text-align: left;
    font-size: 1.3rem;
  }
  .card-img-top {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    height: 150px;
  }
`

export default () => (
  <StaticQuery
    query={graphql`
      {
        services: allContentfulService(sort: { fields: order, order: ASC }) {
          edges {
            node {
              title
              image {
                fluid(maxHeight: 300) {
                  ...GatsbyContentfulFluid
                }
              }
              slug
            }
          }
        }
      }
    `}
    render={data => (
      <Row className="text-center align-items-stretch">
        {data.services.edges.map(({ node: service }) => (
          <Col md="6" lg="3" className="mb-3">
            <StyledCard>
              <Link to={`/services/${service.slug}`}>
                <Img className="card-img-top" fluid={service.image.fluid} />
                <CardHeader tag="h4">{service.title}</CardHeader>
              </Link>
            </StyledCard>
          </Col>
        ))}
      </Row>
    )}
  />
)
