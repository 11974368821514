import React, { useState } from 'react';
import { Collapse, CardBody, Card, CardHeader } from 'reactstrap';
import styled from 'styled-components'

const Question = styled(Card)`
  .card-header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  `

function Faq(props) {
  const [open, setOpen] = useState(false);
  return (
    <Question>
    <CardHeader onClick={() => setOpen(!open)}><span>{props.question}</span><span className="text-right lead ml-3">+</span></CardHeader>
    <Collapse isOpen={open}>
      <CardBody>{props.answer}</CardBody>
    </Collapse>
  </Question>
  )
}

export default Faq
