import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Hero from '../components/hero'
import Testimonials from '../components/testimonials'
import { Button } from '../components/button'
import Faq from '../components/faqs'
import Services from '../components/services'
import { Container } from 'reactstrap'

const IndexPage = () => (
  <Layout>
    <Helmet
      title="Supreme Cleaning - High Standard Window Cleaning in Essex & London"
      meta={[
        {
          name: 'description',
          content:
            'Supreme cleaning specialise in a professional, friendly domestic window cleaning service using nothing but pure water.',
        },
      ]}
    />
    <Hero>
      <h1 className="mb-2">
        Professional window cleaning services for Essex & London
      </h1>
      <p className="mb-5 lead">
        The well trained Supreme Cleaning team have years of experience, and
        always aim to provide the best possible service, tailored for you.
      </p>
      <Button brand to="/contact">
        Contact Us
      </Button>
    </Hero>
    <section>
      <Container>
        <h2 className="display-4 mt-5 text-center">Testimonials</h2>
      </Container>
      <Testimonials />
    </section>
    <section className="background-blue py-5 text-light text-center">
      <Container>
        <h2 className="display-4 mb-5 text-center">About Us</h2>
        <p>
          Supreme Cleaning was established in 2010, and is based in Essex and
          London. We specialise in providing a high standard of window cleaning
          which our customers have come to rely on with full satisfaction.
        </p>
        <p>
          As a family we have been cleaning windows for over 30 years and have
          developed an excellent reputation for quality service in the
          Brentwood, Essex and London areas.
        </p>
        <p className="mb-5">
          We strive to provide all of our customers with a reliable window
          cleaning service both in the domestic and commercial sectors.
        </p>
        <Button accent to="/about">
          Find out more
        </Button>
      </Container>
    </section>
    <section className="py-5">
      <Container>
        <h2 className="display-4 mb-5 text-center">Our Services</h2>
        <Services />
      </Container>
    </section>
    <section>
      <Container>
        <h2 className="display-4 mb-5 text-center">FAQs</h2>
        <Faq />
      </Container>
    </section>
  </Layout>
)

export default IndexPage
